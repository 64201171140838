import React from "react"
import { withFormik } from 'formik'
import * as yup from 'yup'

import Chevron from "../Symbols/chevron"
import PageLink from "../PageLink"

const ContactForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  status
}) => {
  return (
    <form onSubmit={handleSubmit} className="form" name="contact_form" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact_form" />

      <div className="form__group form__group--inline">
        <label htmlFor="fullName" className="form__label" style={{ opacity: `${values.fullName ? 0 : 1}` }}>Full Name</label>        
        <input id="fullName" name="fullName" onChange={handleChange} className="form__input" type="text" value={values.fullName} />
        { touched.fullName && errors.fullName ? <p className="form__error">{errors.fullName}</p> : null }
      </div>
      <div className="form__group form__group--inline">
        <label htmlFor="company" className="form__label" style={{opacity: `${values.company ? 0 : 1}`}}>Company</label>
        <input id="company" name="company" onChange={handleChange}className="form__input" type="text" value={values.company} />
        { touched.company && errors.company ? <p className="form__error">{errors.company}</p> : null }
      </div>
      <div className="form__group form__group--inline">
        <label htmlFor="email" className="form__label" style={{opacity: `${values.email ? 0 : 1}`}}>Email</label>
        <input id="email" name="email" onChange={handleChange} className="form__input" type="email" value={values.email} />
        { touched.email && errors.email ? <p className="form__error">{errors.email}</p> : null }
      </div>
      <div className="form__group form__group--inline">
        <label htmlFor="telephone" className="form__label" style={{opacity: `${values.telephone ? 0 : 1}`}}>Telephone</label>
        <input id="telephone" name="telephone" onChange={handleChange} className="form__input" type="text" value={values.telephone} />
        { touched.telephone && errors.telephone ? <p className="form__error">{errors.telephone}</p> : null }
      </div>
      <div className="form__group">
        <label htmlFor="message" className="form__label form__label--textarea" style={{opacity: `${values.message ? 0 : 1}`}}>
          Message
        </label>
        <textarea id="message" name="message" onChange={handleChange} className="form__input form__input--textarea" value={values.message} />
        { touched.message && errors.message ? <p className="form__error">{errors.message}</p> : null }
      </div>
      <div className="form__group">
        <div className="form__terms">
          <div className="form__terms__input">
              <input
                id="privacy"
                name="privacy"
                className={`form__checkbox ${values.privacy ? 'form__checkbox--checked' : ''}`}
                type="checkbox"
                value={values.privacy}
                onChange={handleChange}
              ></input>
              <label
                htmlFor="privacy"
                className="form__label form__label--checkbox"
              >
                <span>
                  By checking this box I agree to Broadgate Creative’s{" "}
                  <PageLink
                    url="/privacy"
                    className="highlight"
                    data-cursor="alt"
                    menuLink={false}
                  >
                    Privacy Policy
                  </PageLink>
                </span>
              </label>
            </div>
          <button type="submit" className="button button--contact" disabled={isSubmitting}>
            <span>Send</span> <Chevron />
          </button>
        </div>
        { touched.privacy && errors.privacy ? <p className="form__error">{errors.privacy}</p> : null}
        <p className="form__success" style={{display: status ? 'block' : 'none'}}>Thank you for your message, we'll be in touch soon.</p>
      </div>
    </form>  
  )
}

const ContactFormik = withFormik({
  mapPropsToValues({ 
    fullName,
    company,
    email,
    telephone,
    message,
    privacy
  }) {
    return {
      fullName: '',
      company: '',
      email: '',
      telephone: '',
      message: '',
      privacy: false
    }
  },
  validationSchema: yup.object().shape({
      fullName: yup
          .string()
          .min(3, 'Full Name is too short')
          .max(100, 'Full Name is too long')
          .required('Full Name is required'),
      company: yup
          .string()
          .min(2, 'Company Name is too short')
          .max(100, 'Company Name is too long'),
      email: yup
          .string()
          .email('Please enter a valid email address')
          .required('Email Address is required'),
      telephone: yup
        .number('This field must be a number')
        .required('Telephone Number is required'),
      company: yup
          .string()
          .min(2, 'Company Name is too short')
        .max(100, 'Company Name is too long'),
      privacy: yup
          .boolean()
          .oneOf([true], 'Please tick to accept our Privacy Policy')
  }),
  handleChange(e) {
    e.target.value
  },
  handleSubmit(values, actions) {
      fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact_form", ...values }) // see 'encode' func
      }).then(() => {
          // Show a success message
          console.log('Form successfully sent', actions)
          actions.setSubmitting(false)
          actions.resetForm()
          actions.setStatus('success');
        }
      )
      .catch(
        error => console.log(error)
      );   
      
      e.preventDefault();
  }
})(ContactForm)

// This is used to encode data to submit form to Netlify
const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default ContactFormik