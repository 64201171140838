import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { graphql } from "gatsby"

import { toggleBackground } from "../state/background"
import { setBreadcrumbs } from "../state/breadcrumbs"
import { toggleStickyHeaderColour } from "../state/header"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import ContactFormik from "../components/ContactForm"

const Contact = props => {
  const {
    dispatch,
    location,
    transitionStatus,
    data: { allWpPage },
  } = props
  const {
    title,
    background,
    slug,
    seo,
    hero,
    contact,
  } = allWpPage.edges[0].node

  useEffect(() => {
    // change bg and sticky header colour instantly,
    dispatch(toggleBackground(background.backgroundColour))
    setTimeout(() => {
      dispatch(toggleStickyHeaderColour(background.backgroundColour))
    }, 1000)
  }, [])

  useEffect(() => {
    // update breadcrumbs state
    setTimeout(() => {
      dispatch(
        setBreadcrumbs({
          current: {
            title: title,
            path: slug ? slug : `contact`,
          },
        })
      )
    }, 1000)
  }, [])

  return (
    <SiteLayout location={location}>
      <Seo
        seoTitle={seo.title ? seo.title : title}
        seoDesc={seo.metaDesc ? seo.metaDesc : null}
        ogTitle={seo.opengraphTitle ? seo.opengraphTitle : seo.title}
        ogDesc={
          seo.opengraphDescription ? seo.opengraphDescription : seo.metaDesc
        }
        ogImage={
          seo.opengraphImage && seo.opengraphImage.localFile
            ? seo.opengraphImage.localFile.url
            : null
        }
      />

      <Hero
        slogan={hero.heroSlogan}
        halfHeight
        transitionStatus={transitionStatus}
      />

      <main id="main" className="main-content main-content--half">
        <section className="section section--pure-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12 col-bp3-12 col-bp4-6">
                <div className="row">
                  <div className="col-bp1-12">
                    <div
                      className="intro intro--contact"
                      dangerouslySetInnerHTML={{
                        __html: contact.contactDetails[0].contactDetailsContent,
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  {contact.contactDetails[1] ? (
                    <div className="col-bp1-12 col-bp2-6 col-bp4-12">
                      <div
                        className="entry entry--contact"
                        dangerouslySetInnerHTML={{
                          __html:
                            contact.contactDetails[1].contactDetailsContent,
                        }}
                      />
                    </div>
                  ) : null}
                  {contact.contactDetails[2] ? (
                    <div className="col-bp1-12 col-bp2-6 col-bp4-12">
                      <div
                        className="entry entry--contact"
                        dangerouslySetInnerHTML={{
                          __html:
                            contact.contactDetails[2].contactDetailsContent,
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-bp1-12 col-bp3-12 col-bp4-6">
                <ContactFormik />
              </div>
            </div>
          </div>
        </section>
      </main>
    </SiteLayout>
  )
}

export default connect(
  state => ({
    breadcrumbs: state.breadcrumbs.breadcrumbs,
  }),
  null
)(Contact)

export const pageQuery = graphql`
  query GET_CONTACT_PAGE($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          content
          slug
          seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphImage {
              localFile {
                url
              }
            }
          }
          background {
            backgroundColour
          }
          hero {
            heroSlogan
          }
          contact {
            contactDetails {
              contactDetailsContent
            }
          }
        }
      }
    }
  }
`
